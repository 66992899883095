import React from "react";
import poster from "../../assests/gallery/poster.jpg";
import video1 from "../../assests/gallery/4.mp4";
import img1 from "../../assests/gallery/1.jpg";
import img2 from "../../assests/gallery/2.jpg";
import img3 from "../../assests/gallery/3.jpg";
import img4 from "../../assests/gallery/4.jpg";

function Gallery() {
  return (
    <div className="w-11/12 mx-auto my-10 mt-[100px]">
      <div className="flex flex-col w-full items-center">
        <h3 className="text-4xl font-fjalla text-[#33536B]">Gallery</h3>
        <div className="flex items-center w-[75px]">
          <div className="h-0.5 bg-[#e2571a]"></div>
          <div className="h-1 w-1 bg-[#e2571a] rounded-full mx-1"></div>
          <div className="h-1 w-1 bg-[#e2571a] rounded-full mx-1"></div>
          <div className="h-1 w-1 bg-[#e2571a] rounded-full mx-1"></div>
          <div
            className="h-[4px] rounded-full w-[10px] flex-grow"
            style={{ backgroundColor: "#e2571a" }}
          ></div>
        </div>
      </div>

      <div className="my-10">
        <img src={poster} alt="" className="w-full h-auto" />
      </div>

      <div className="flex justify-between w-[90vw] flex-wrap gap-4">
        <img src={img1} alt="" className="lg:w-[20%] p-4 border rounded-xl" />
        <img src={img3} alt="" className="lg:w-[40%] p-4 border rounded-xl" />
        <img src={img2} alt="" className="lg:w-[20%] p-4 border rounded-xl" />
      </div>

      <div className="mt-14">
        <div className="">
          <video
            src={video1}
            autoPlay
            controls
            muted
            className="w-[90vw] h-[30vw]"
          ></video>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
