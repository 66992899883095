import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ProductCard from "../common/ProductCard";





const ProductSection = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [product, setProduct] = useState([]);
  const getAllProduct = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/product/getAll`);

      console.log(response);
      if (response?.data?.success) {
        setProduct(response.data.products);
        console.log(response.data.products);
      }
    } catch (error) {
      console.log("Something went wrong");
    }
  };


  
  useEffect(() => {
    getAllProduct();
  }, []);

  return (
    <>
      <div className="container mx-auto mt-8 w-11/12">
        <div className="flex flex-col items-center">
          <h3 className="text-3xl font-bold mb-4 lg:w-[60%] w-[90%] text-center text-[#33536B]">
            Discover Our Premium Selection
          </h3>
          <div className="flex items-center w-[75px]">
            <div className="h-0.5 bg-[#e2571a]"></div>
            <div className="h-1 w-1 bg-[#e2571a] rounded-full mx-1"></div>
            <div className="h-1 w-1 bg-[#e2571a] rounded-full mx-1"></div>
            <div className="h-1 w-1 bg-[#e2571a] rounded-full mx-1"></div>
            <div
              className="h-[4px] rounded-full w-[10px] flex-grow"
              style={{ backgroundColor: "#e2571a" }}
            ></div>
          </div>

          <div className="text-[#5A5A5A] mt-3 lg:w-[60%] w-[90%] text-center">
          Our meals are a healthy combination of whole grains, veggies, pulses and spices and provide essential nutrients like proteins, carbohydrates, fibre etc without compromising on taste
          </div>
        </div>

        <div className="bg-gray-100 p-8">
  

        {product.length === 0 ? (
          <div className="flex w-screen min-h-[200px] items-center justify-center">
            <div className="spinner"></div>
          </div>
        ) : (
          <>

                {/* Products */}

                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-4 place-content-center w-full place-items-center mt-12 ">
                  {product?.map((product, index) => (
                    <ProductCard
                      index={index}
                      key={index}
                      imageUrl={product.image}
                      title={product.name}
                      category={product.category}
                      price={product?.price}
                    />
                  ))}
                </div>
                      </>
        )}

    





    </div>
      </div>

      <div className="flex justify-center mt-8">
        <Link
          className="bg-[#333333] text-white py-2 px-6 rounded-sm uppercase font-sans font-semibold hover:bg-[#e2571a] transition duration-300 ease-in-out"
          to="/product"
        >
          View All Meals
        </Link>
      </div>
    </>
  );
};

export default ProductSection;
