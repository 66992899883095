import React from 'react';
import { FaUtensils, FaLeaf, FaHandshake, FaHeart } from 'react-icons/fa';

const sections = [
  {
    icon: <FaUtensils className="text-[150px] text-green-600 mr-4" />,
    title: 'Delicious Homemade Meals',
    description:
      'At Vaishanvi Tiffin Service, we provide delicious and nutritious homemade meals, crafted with love and the freshest ingredients, ensuring a taste of home in every bite.'
  },
  {
    icon: <FaLeaf className="text-[150px] text-blue-600 mr-4" />,
    title: 'Health and Hygiene First',
    description:
      'Our commitment to health and hygiene is unparalleled. We adhere to strict cleanliness protocols and use only the highest quality ingredients to ensure your meals are both safe and healthy.'
  },
  {
    icon: <FaHandshake className="text-[150px] text-green-600 mr-4" />,
    title: 'Reliable and Timely Service',
    description:
      'We understand the importance of timely meals. Our reliable delivery service ensures your tiffin reaches you hot and fresh, right on time, every time.'
  },
  {
    icon: <FaHeart className="text-[150px] text-blue-600 mr-4" />,
    title: 'Personalized Meal Plans',
    description:
      'Whether you have dietary restrictions or specific preferences, we offer personalized meal plans to cater to your unique needs, ensuring a satisfying dining experience.'
  }
];

const WhyChoose = () => {
  return (
    <div className="w-11/12 mx-auto py-8 px-4">

      <div className='w-full flex justify-between flex-wrap gap-3'>
        <div>
          <p className='text-[#df794d] font-sans italic lg:text-2xl text-xl'>Why Choose Us</p>
          <p className='lg:text-5xl text-4xl text-[#33536B] font-semibold font-sans'>Experience the Best Tiffin Service</p>
        </div>

        <div className='lg:w-[50%] w-full'>
          Discover why Vaishanvi Tiffin Service is your top choice for home-cooked meals delivered with care and consistency.
        </div>
      </div>

      <div className="flex flex-wrap justify-center -mx-4 mt-[50px]">
        {sections.map((section, index) => (
          <div key={index} className="w-full sm:w-1/2 px-4 mb-8">
            <div className="flex items-center">
              {section.icon}
              <div>
                <h2 className="text-xl mb-2 text-[#33536B] font-sans">{section.title}</h2>
                <p className="text-[#5A5A5A] text-sm lg:text-base">{section.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhyChoose;
