import React, { useEffect, useState } from "react";
import axios from "axios";

import Navbar from "../component/Header/Navbar";
import ProductCard from "../component/common/ProductCard";

import Footer from "../component/common/Footer";


function AllProduct() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [dryFruits, setDryFruits] = useState([]);
  const [millet, setMillet] = useState([]);
  const [pulses, setPulses] = useState([]);
  const [spices, setSpices] = useState([]);
  const [vegetable, setVegetable] = useState([]);
  const [powders, setPowders] = useState([]);
  const [rice, setRice] = useState([]);
  const [fruits, setFruits] = useState([]);
  
  const [product, setProduct] = useState([]);
  const getAllProduct = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/product/getAll`);

      console.log(response);
      if (response?.data?.success) {
        setProduct(response.data.products);
        console.log(response.data.products);
      }
    } catch (error) {
      console.log("Something went wrong");
    }
  };

  useEffect(() => {
    getAllProduct();
  }, []);

  return (
    <>
      <Navbar />

      <div className=" w-screen">
        <div className="relative flex justify-center items-center w-full min-h-[50vh] -mt-2 bg-gradient-to-r bg-black">
          <div
            className="absolute inset-0 w-full h-full bg-cover bg-center bg-no-repeat opacity-40"
            style={{
              backgroundImage: `url(https://media-assets.swiggy.com/swiggy/image/upload/f_auto,q_auto,fl_lossy/pg27ub0kdicnzkmsdghl)`,
            }}
          />
          <div className="relative z-50 text-[#fff5f5] font-semibold text-5xl bg-[#e2571a] p-1 rounded-2xl">
            Melas Menu
          </div>
        </div>
              
  



                {product.length === 0 ? (
          <div className="flex w-screen min-h-[200px] items-center justify-center">
            <div className="spinner"></div>
          </div>
        ) : (
          <div className=" my-4 w-11/12 mx-auto">

                {/* Products */}
                <div className=" flex flex-col  w-full items-center">
                  <h3 className="  text-4xl font-fjalla text-[#33536B]">
                    Menu
                  </h3>
                  <div className="flex items-center w-[75px]">
                    <div className="h-0.5 bg-[#e2571a]"></div>
                    <div className="h-1 w-1 bg-[#e2571a] rounded-full mx-1"></div>
                    <div className="h-1 w-1 bg-[#e2571a] rounded-full mx-1"></div>
                    <div className="h-1 w-1 bg-[#e2571a] rounded-full mx-1"></div>
                    <div
                      className="h-[4px] rounded-full w-[10px] flex-grow"
                      style={{ backgroundColor: "#e2571a" }}
                    ></div>
                  </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-4 place-content-center w-full place-items-center mt-12 ">
                  {product?.map((product, index) => (
                    <ProductCard
                      index={index}
                      key={index}
                      imageUrl={product.image}
                      title={product.name}
                      category={product.category}
                      price={product?.price}
                    />
                  ))}
                </div>
                      </div>
        )}


      </div>
      <Footer />
    </>
  );
}

export default AllProduct;
