import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import tifin from "../../assests/shutterstock_616517744-1-scaled.jpg";

function AboutSection() {
    const [count, setCount] = useState(1);

    useEffect(() => {
        const interval = setInterval(() => {
            setCount((prevCount) => (prevCount >= 25 ? 25 : prevCount + 1));
        }, 10);

        if (count === 25) {
            clearInterval(interval); // Clear interval when count reaches 25
        }

        return () => clearInterval(interval); // Cleanup function to clear interval on unmount
    }, [count]); // Dependency on count ensures useEffect runs on count change

    return (
        <div className="w-11/12 mx-auto mt-20 mb-40 flex justify-between flex-wrap-reverse">
            {/* Image Section */}
            <div className="relative lg:w-[44%] md:w-[44%] w-full">
                <img
                    src={tifin}
                    alt="Tiffin Service"
                    className="w-full h-[40vh] lg:h-full object-cover"
                />
                <div className="absolute bottom-8 right-4 text-white font-bold text-lg z-10 text-center bg-white lg:p-4 p-1 rounded-lg shadow-lg backdrop-filter backdrop-blur-sm" style={{ backgroundColor: 'rgba(255, 255, 255, 0.7)' }}>
                    <p className="lg:text-6xl text-[#e2571a]">{count}+</p>
                    <p className='text-[#5A5A5A]'>Years of Experience</p>
                </div>
            </div>

            {/* Data Section */}
            <div className="lg:w-1/2 md:w-1/2 w-full bg-white shadow-lg rounded-lg p-6">
                <p className="text-[#e2571a] font-sans italic text-lg">About Vaishanvi Tiffin Service</p>
                <h2 className="text-[#33536B] lg:text-4xl md:text-4xl text-2xl font-sans font-bold mt-2 uppercase">
                    Providing Homely Meals with Love and Care
                </h2>
                <p className="text-[#5A5A5A] mt-2 leading-relaxed text-[12px] lg:text-[14px]">
                    Vaishanvi Tiffin Service is your go-to solution for wholesome, home-cooked meals delivered right to your doorstep. 
                    With over 25 years of experience, we take pride in serving nutritious and delicious meals prepared with the utmost 
                    care and hygiene. Our tiffin service is designed to cater to individuals and families who crave the taste of home-cooked 
                    food amidst their busy schedules. We believe in using fresh ingredients and traditional recipes to bring you a delightful 
                    dining experience every day. At Vaishanvi Tiffin Service, our commitment is to provide you with meals that are not only 
                    tasty but also nourishing, ensuring you feel right at home with every bite.
                </p>
                <div className="mt-4">
                    <Link
                        to="/product"
                        className="bg-[#e2571a] text-white py-2 px-6 rounded-lg uppercase font-sans font-semibold hover:bg-[#5A5A5A] transition duration-300 ease-in-out"
                    >
                        Discover Our Menu
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default AboutSection;
