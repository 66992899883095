import React, { useEffect, useState } from "react";
import { FaRupeeSign } from "react-icons/fa";

const ProductCard = ({ imageUrl, title, category, index ,price}) => {
  const buttonColorClass = index % 2 === 0 ? "bg-[#5A5A5A]" : "bg-[#e2571a]";

  const generateWhatsAppLink = (productName) => {
    const phoneNumber = 919179414014 // Replace with your WhatsApp number
    const message = `Hello, I am interested in the Meal: ${productName}`;
    return `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
  };

  return (
    <div className="rounded overflow-hidden shadow-lg bg-white text-center">
      <div className="bg-white shadow-md rounded-lg overflow-hidden border-2">
        <div className="w-full h-64 overflow-hidden flex items-center justify-center">
          <img
            src={imageUrl}
            alt="Product"
            className="max-h-full max-w-full object-cover"
          />
        </div>
        <div className="px-2 py-2 min-h-[200px] flex flex-col justify-start items-start">
          <div className="font-bold text-[18px] mb-2 text-teal-700 font-sans text-wrap text-start min-h-[30px] ">
            {title}
          </div>
          <p className="text-gray-600 text-[13px] mb-1 text-start min-h-[70px] max-h-[70px]">
            <span className="font-bold"></span> {category}
          </p>
          <p className="text-gray-900 text-base mb-4 flex items-center g-1 text-start font-bold justify-center">
           <FaRupeeSign size={12} /> {price} 
          </p>
          <a
            href={generateWhatsAppLink(title)}
            className={`text-white w-full font-serif py-2 px-4 rounded ${buttonColorClass} hover:bg-opacity-75`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Send Inquiry
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
